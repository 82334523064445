<template>
  <div>
    <sectionHeading text="Mathematical Laboratory" />

    <p>
      Mathematics being a compulsory subject of study, access to quality
      mathematics education is every child's right. On the other hand,
      mathematics education in our schools is beset with problems. Due to the
      hierarchy of concepts and largely deductive and abstract nature of the
      subject, mathematics is considered as a very dull and difficult subject. A
      phobia has been created in the minds of the children that mathematics is
      tough to learn. As a result, most of the students are not taking interest
      in the subject and it has become one of the main causes of student's
      failure in mathematics.
    </p>
    <p>
      A shift in focus is needed in mathematical teaching especially in
      mathematical learning environment, where whole ranges of process take
      precedence: Formal problem solving, Use of heuristics, Estimation and
      Approximation, Optimization and Mathematical Communication.
    </p>
    <p>
      The National Curriculum Framework for School education developed by NCERT
      strongly suggested setting up of a mathematics laboratory in every school
      in order to help exploration of mathematical concepts through activities
      and experimentation.
    </p>
    <p>
      The main aim of the mathematics laboratory is to initiate children to
      enjoy mathematics, pose and solve meaningful problems, to use abstraction
      to perceive relationships and structure and to understand the basic
      structure of mathematics.
    </p>
    <h4>NEED AND PURPOSE</h4>
    <ul style="padding-left:15px;">
      <li>
        It enables the student to learn mathematics with the help of concrete
        objects and to exhibit the relatedness of mathematics with everyday
        life.
      </li>
      <li>
        It helps the students to verify or discover some geometric properties
        using models & measurements.
      </li>
      <li>
        It provides opportunity for students to understand and internalize the
        basic mathematical concepts through concrete objects and situations.
      </li>
      <li>
        It helps the students to build interest and confidence in learning the
        subject.
      </li>
      <li>
        It provides greater scope for individual participation in the process of
        learning and becoming autonomous learners.
      </li>
      <li>
        It provides scope for greater involvement of both the mind and the hand
        which facilitates cognition.
      </li>
      <li>
        It enables the teacher to demonstrate, explain and reinforce abstract
        mathematical ideas by using concrete objects, models, charts, graphs,
        pictures, posters etc.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "mathematicalLaboratory"
};
</script>
<style lang="scss" scoped>
</style>